<template>
  <v-card>
    <validation-observer>
      <v-row v-if="canList" align="center" class="px-8">
        <v-col cols="1">
          <v-card-title>Filtro</v-card-title>
        </v-col>
        <v-col cols="10">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <validation-provider name="colaborador">
                  <v-autocomplete
                    v-model="form.colaborador"
                    label="Colaborador"
                    name="colaborador"
                    :items="colaboradores"
                    :loading="loadingColaboradores"
                    item-text="user.name"
                    item-value="public_id"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider name="data_inicio">
                  <conferencia-list-filtro-data
                    v-model="form.data_inicio"
                    propLabel="De"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider name="data_fim">
                  <conferencia-list-filtro-data
                    v-model="form.data_fim"
                    propLabel="Até"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <v-card-actions>
            <v-btn
              color="primary"
              title="Remover filtros"
              :disabled="!formIsValid"
              @click="clear"
              ><v-icon>mdi-filter-remove</v-icon></v-btn
            >
            <v-btn
              color="primary"
              title="Pesquisar"
              :disabled="!formIsValid"
              @click="search"
              ><v-icon>mdi-database-search</v-icon></v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
    </validation-observer>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ConferenciaListFiltroData from './ConferenciaListFiltroData.vue'



export default {
  name: 'ConferenciaListFiltro',
  props: {
    tela: {
      type: String,
      required: true,
    },
  },
  components: { ConferenciaListFiltroData },
  data() {
    return {
      loadingColaboradores: false,
      form: this.getDefault(),
    }
  },
  computed: {
    ...mapState('gerencial', ['colaboradores']),
    formIsValid() {
      const { colaborador, data_inicio, data_fim } = this.form
      return Boolean(colaborador || data_inicio || data_fim)
    },
    canList() {
      return true
      // return this.$canDo(BasicPermissions.VISUALIZAR, this.tela)
    },
  },
  methods: {
    ...mapActions('gerencial', ['getColaboradores']),
    getDefault() {
      return {
        colaborador: '',
        data_inicio: '',
        data_fim: '',
      }
    },
    clear() {
      this.form = this.getDefault()
      this.$emit('search', this.form)
    },
    search() {
      if (this.formIsValid) this.$emit('search', this.form)
    },
  },
  async created() {
    if (this.canList) {
      this.loadingColaboradores = true
      await this.getColaboradores()
      this.loadingColaboradores = false
    }
  },
}
</script>
