<template>
  <v-data-table
    id="table-conferencia"
    :headers="caixasEmAberto.headers"
    :items="caixasEmAberto.caixas"
    sort-by="data_hora_abertura"
    sort-desc
    class="border"
    @click:row="resumeItem"
  >
    <template v-slot:[`item.numero`]="{ item: { numero } }">
      <a class="secondary--text">{{ numero }}</a>
    </template>
    <template
      v-slot:[`item.data_hora_abertura`]="{ item: { data_hora_abertura } }"
    >
      {{ data_hora_abertura | toLocaleDateTimeString }}
    </template>
    
    <template
      v-for="header in caixasEmAberto.headers"
      v-slot:[`item.${header.text}`]="{ item }"
    >
      <div v-bind:key="header.value" class="d-flex flex-column">
        <span class="">
          {{  toReal(item.forma_pagamento.filter((item) => item.forma_pagamento_name === header.text).length > 0 ? item.forma_pagamento.filter((item) => item.forma_pagamento_name === header.text)[0].total : 0)   }}
        </span>
      </div>
    </template>

    <template v-slot:[`item.valor_em_caixa`]="{ item: { valor_em_caixa } }">
      {{ toReal(valor_em_caixa) }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        v-if="canUpdate"
        color="primary"
        @click="resumeItem(item)"
        title="Visualizar Resumo"
        >mdi-eye</v-icon
      >
      <v-icon
        v-if="canUpdate"
        class="ml-2"
        color="primary"
        @click="visualizarExtrato(item.id)"
        title="Visualizar Extrato"
        >mdi-file-chart</v-icon
      >
    </template>
    <template v-if="!canList" v-slot:no-data>
      <span class="red--text"
        >Você não tem permissão para visualizar as informações desta tela</span
      >
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { toLocaleDateTimeString, toReal } from '@/mixins/convertion'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ConferenciaListEmAberto',
  inject: ['resumeItem'],
  mixins: [toReal],
  props: {
    tela: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Envelope',
          value: 'numero',
          sortable: false,
        },
        { text: 'Colaborador', value: 'colaborador_nome' },
        { text: 'Data/Hora Abertura', value: 'data_hora_abertura' },
        { text: 'Valor em Caixa', value: 'valor_em_caixa' },
        { text: 'Forma de pagamento', value: 'forma_pagamento' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },
  filters: { toLocaleDateTimeString },
  computed: {
    ...mapState('conferencia', ['caixasEmAberto']),

    permission() {
      return Sequences.Conferencia.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('conferencia', ['getEmAberto']),
    async getItems(params) {
      try {
        await this.getEmAberto({ params })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    visualizarExtrato(caixaID) {
      this.$router.push({
        name: 'extrato',
        params: {
          caixaID,
        },
      })
    },
  },
  async created() {
    if (this.canList) {
      await this.getItems()
    }
  },
}
</script>

<style lang="scss">
#table-conferencia {
  tr {
    cursor: pointer;
  }
}
</style>
