var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('conferencia-base-dialog',{attrs:{"value":_vm.showDelete},on:{"cancelar":function($event){_vm.showDelete = false},"confirmar":_vm.confirmDelete,"close":function($event){_vm.showDelete = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Deseja realmente conferir novamente? ")]},proxy:true}])}),_c('conferencia-base-dialog',{attrs:{"value":_vm.showFinish},on:{"cancelar":function($event){_vm.showFinish = false},"confirmar":_vm.confirmFinish,"close":function($event){_vm.showFinish = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Deseja realmente finalizar? ")]},proxy:true}])}),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.items.headers,"items":_vm.items.caixas,"sort-by":"conferido_em","sort-desc":""},scopedSlots:_vm._u([{key:"item.caixa.data_hora_abertura",fn:function(ref){
      var data_hora_abertura = ref.item.caixa.data_hora_abertura;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(data_hora_abertura))+" ")]}},{key:"item.caixa.data_hora_fechamento",fn:function(ref){
      var data_hora_fechamento = ref.item.caixa.data_hora_fechamento;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(data_hora_fechamento))+" ")]}},_vm._l((_vm.items.headers),function(header){return {key:("item." + (header.text)),fn:function(ref){
      var item = ref.item;
return [_c('div',{key:header.value,staticClass:"d-flex flex-column"},[_c('span',{},[_vm._v(" "+_vm._s(_vm.toReal(item.forma_pagamento.filter(function (item) { return item.forma_pagamento_name === header.text; }).length > 0 ? item.forma_pagamento.filter(function (item) { return item.forma_pagamento_name === header.text; })[0].total : 0))+" ")])])]}}}),{key:"item.caixa.valor_em_caixa",fn:function(ref){
      var valor_em_caixa = ref.item.caixa.valor_em_caixa;
return [_vm._v(" "+_vm._s(_vm.toReal(valor_em_caixa))+" ")]}},{key:"item.conferido_em",fn:function(ref){
      var conferido_em = ref.item.conferido_em;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(conferido_em))+" ")]}},{key:"item.valor_conferido",fn:function(ref){
      var valor_conferido = ref.item.valor_conferido;
return [_vm._v(" "+_vm._s(_vm.toReal(valor_conferido))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',[(_vm.canUpdate)?_c('v-icon',{staticClass:"mx-1",attrs:{"title":"Editar Conferência","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{attrs:{"color":"primary","title":"Visualizar Resumo"},on:{"click":function($event){return _vm.resumeItem(item.caixa)}}},[_vm._v("mdi-eye")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary","title":"Visualizar Extrato"},on:{"click":function($event){return _vm.visualizarExtrato(item.caixa.id)}}},[_vm._v("mdi-file-chart")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{staticClass:"mx-1",attrs:{"title":"Conferir Novamente","color":"error"},on:{"click":function($event){return _vm.deleteItem(item.public_id)}}},[_vm._v("mdi-restore")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{staticClass:"mx-1",attrs:{"title":"Finalizar Conferência","color":"green"},on:{"click":function($event){return _vm.finishItem(item.public_id)}}},[_vm._v("mdi-flag-checkered")]):_vm._e()],1)]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }