var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"border",attrs:{"id":"table-conferencia","headers":_vm.caixasPendentes.headers,"items":_vm.caixasPendentes.caixas,"sort-by":"data_hora_fechamento","sort-desc":""},on:{"click:row":_vm.resumeItem},scopedSlots:_vm._u([{key:"item.numero",fn:function(ref){
var numero = ref.item.numero;
return [_c('a',{staticClass:"secondary--text"},[_vm._v(_vm._s(numero))])]}},{key:"item.data_hora_abertura",fn:function(ref){
var data_hora_abertura = ref.item.data_hora_abertura;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(data_hora_abertura))+" ")]}},{key:"item.data_hora_fechamento",fn:function(ref){
var data_hora_fechamento = ref.item.data_hora_fechamento;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(data_hora_fechamento))+" ")]}},{key:"item.valor_em_caixa",fn:function(ref){
var valor_em_caixa = ref.item.valor_em_caixa;
return [_vm._v(" "+_vm._s(_vm.toReal(valor_em_caixa))+" ")]}},_vm._l((_vm.caixasPendentes.headers),function(header){return {key:("item." + (header.text)),fn:function(ref){
var item = ref.item;
return [_c('div',{key:header.value,staticClass:"d-flex flex-column"},[_c('span',{},[_vm._v(" "+_vm._s(_vm.toReal(item.forma_pagamento.filter(function (item) { return item.forma_pagamento_name === header.text; }).length > 0 ? item.forma_pagamento.filter(function (item) { return item.forma_pagamento_name === header.text; })[0].total : 0))+" ")])])]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{attrs:{"color":"primary","title":"Visualizar Resumo"},on:{"click":function($event){return _vm.resumeItem(item)}}},[_vm._v("mdi-eye")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary","title":"Visualizar Extrato"},on:{"click":function($event){return _vm.visualizarExtrato(item.id)}}},[_vm._v("mdi-file-chart")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }