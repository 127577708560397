<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="48" />
    </v-overlay>
    <conferencia-list-filtro
      :tela="telaConferencia"
      class="my-4"
      @search="search" />

    <v-tabs v-model="tab">
      <v-tab href="#emaberto">Em aberto</v-tab>
      <v-tab v-if="canAbaPendentes" href="#pendentes">Pendentes</v-tab>
      <v-tab v-if="canAbaAprovados" href="#aprovados">Aprovados</v-tab>
      <v-tab v-if="canAbaReprovados" href="#reprovados">Reprovados</v-tab>
      <v-tab v-if="canAbaConcluidos" href="#concluidas">Concluídas</v-tab>

      <v-tabs-items v-model="tab" class="mt-2">

        <v-tab-item value="emaberto">
          <conferencia-list-em-aberto 
            ref="emaberto"/>
        </v-tab-item>

        <v-tab-item value="pendentes">
          <conferencia-list-pendentes 
            ref="pendentes"/>
        </v-tab-item>

        <v-tab-item value="aprovados">
          <conferencia-list-aprovados 
            ref="aprovados"
            />
        </v-tab-item>

        <v-tab-item value="reprovados">
          <conferencia-list-reprovados ref="reprovados" />
        </v-tab-item>

        <v-tab-item value="concluidas">
          <conferencia-list-concluidas ref="concluidas" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ConferenciaListFiltro from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListFiltro.vue'
import ConferenciaListPendentes from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListPendentes.vue'
import ConferenciaListEmAberto from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListEmAberto.vue'
import ConferenciaListAprovados from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListAprovados.vue'
import ConferenciaListReprovados from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListReprovados.vue'
import ConferenciaListConcluidas from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListConcluidas.vue'
import { BasicPermissions, Sequences, CustomPermissions } from '@/utils/permissions'

export default {
  name: 'ConferenciaList',
  components: {
    ConferenciaListFiltro,
    ConferenciaListPendentes,
    ConferenciaListEmAberto,
    ConferenciaListAprovados,
    ConferenciaListReprovados,
    ConferenciaListConcluidas,
  },
  data() {
    return {
      Sequences,
      loading: false,
      tab: '',
    }
  },
  methods: {
    ...mapActions('conferencia', ['getConferencias']),
    async search(params) {
      this.loading = true
      await this.$refs[this.tab].getItems(params)
      this.loading = false
    },
  },
  computed: {
    telaConferencia() {
      return Sequences.Conferencia.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.telaConferencia)
    },
    canAbaPendentes() {
      return this.$canDo(CustomPermissions.ABA_PENDENTES, this.telaConferencia)
    },
    canAbaAprovados() {
      return this.$canDo(CustomPermissions.ABA_APROVADOS, this.telaConferencia)
    },
    canAbaReprovados() {
      return this.$canDo(CustomPermissions.ABA_REPROVADOS, this.telaConferencia)
    },
    canAbaConcluidos() {
      return this.$canDo(CustomPermissions.ABA_CONCLUIDOS, this.telaConferencia)
    },
  },
  async created() {
    if (this.canList) {
      this.loading = true
      await this.getConferencias({})
      this.loading = false
    }
  },
}
</script>
