<template>
  <div>
    <conferencia-base-dialog
      :value="showDelete"
      @cancelar="showDelete = false"
      @confirmar="confirmDelete"
      @close="showDelete = false"
    >
      <template #title> Deseja realmente conferir novamente? </template>
    </conferencia-base-dialog>

    <conferencia-base-dialog
      :value="showFinish"
      @cancelar="showFinish = false"
      @confirmar="confirmFinish"
      @close="showFinish = false"
    >
      <template #title> Deseja realmente finalizar? </template>
    </conferencia-base-dialog>

    <v-data-table
      :headers="items.headers"
      :items="items.caixas"
      sort-by="conferido_em"
      sort-desc
      class="border"
    >
      <template
        v-slot:[`item.caixa.data_hora_abertura`]="{
          item: {
            caixa: { data_hora_abertura },
          },
        }"
      >
        {{ data_hora_abertura | toLocaleDateTimeString }}
      </template>
      <template
        v-slot:[`item.caixa.data_hora_fechamento`]="{
          item: {
            caixa: { data_hora_fechamento },
          },
        }"
      >
        {{ data_hora_fechamento | toLocaleDateTimeString }}
      </template>
      <template
        v-for="header in items.headers"
        v-slot:[`item.${header.text}`]="{ item }"
      >
        <div v-bind:key="header.value" class="d-flex flex-column">
          <span class="">
            {{  toReal(item.forma_pagamento.filter((item) => item.forma_pagamento_name === header.text).length > 0 ? item.forma_pagamento.filter((item) => item.forma_pagamento_name === header.text)[0].total : 0)   }}
          </span>
        </div>
      </template>
      <template
        v-slot:[`item.caixa.valor_em_caixa`]="{
          item: {
            caixa: { valor_em_caixa },
          },
        }"
      >
        {{ toReal(valor_em_caixa) }}
      </template>
      <template v-slot:[`item.conferido_em`]="{ item: { conferido_em } }">
        {{ conferido_em | toLocaleDateTimeString }}
      </template>
      <template v-slot:[`item.valor_conferido`]="{ item: { valor_conferido } }">
        {{ toReal(valor_conferido) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-icon
            v-if="canUpdate"
            class="mx-1"
            title="Editar Conferência"
            color="primary"
            @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            color="primary"
            v-if="canUpdate"
            @click="resumeItem(item.caixa)"
            title="Visualizar Resumo"
            >mdi-eye</v-icon
          >
           <v-icon
            v-if="canUpdate"
            class="ml-2"
            color="primary"
            @click="visualizarExtrato(item.caixa.id)"
            title="Visualizar Extrato"
            >mdi-file-chart</v-icon
          >
          <v-icon
            v-if="canDelete"
            class="mx-1"
            title="Conferir Novamente"
            color="error"
            @click="deleteItem(item.public_id)"
            >mdi-restore</v-icon
          >
          <v-icon
            v-if="canDelete"
            class="mx-1"
            title="Finalizar Conferência"
            color="green"
            @click="finishItem(item.public_id)"
            >mdi-flag-checkered</v-icon
          >
        </div>
      </template>
      <template v-if="!canList" v-slot:no-data>
      <span class="red--text"
        >Você não tem permissão para visualizar as informações desta tela</span
      >
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { toLocaleDateTimeString, toReal } from '@/mixins/convertion'
import { ConferenciaStatus } from '@/utils/conferencia'

import ConferenciaBaseDialog from '../ConferenciaBaseDialog.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ConferenciaListGeral',
  inject: ['editItem', 'resumeItem'],
  mixins: [toReal],
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  components: { ConferenciaBaseDialog },
  data() {
    return {
      headers: [
        {
          text: 'Envelope',
          value: 'caixa.numero',
          sortable: false,
        },
        { text: 'Colaborador', value: 'caixa.colaborador_nome' },
        { text: 'Data/Hora Abertura', value: 'caixa.data_hora_abertura' },
        { text: 'Data/Hora Fechamento', value: 'caixa.data_hora_fechamento' },
        { text: 'Valor em Caixa', value: 'caixa.valor_em_caixa' },
        { text: 'Conferido Em', value: 'conferido_em' },
        { text: 'Conferido Por', value: 'conferido_por' },
        { text: 'Valor Conferido', value: 'valor_conferido' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      conferenciaPublicID: null,
      showDelete: false,
      showFinish: false,
      ConferenciaStatus,
    }
  },
  filters: { toLocaleDateTimeString },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('conferencia', ['deleteConferencia', 'concluirConferencia']),

    deleteItem(conferenciaPublicID) {
      this.conferenciaPublicID = conferenciaPublicID
      this.showDelete = true
    },
    async confirmDelete() {
      try {
        await this.deleteConferencia({
          conferenciaPublicID: this.conferenciaPublicID,
        })
        this.showDelete = false
        this.successMessage('Conferência removida com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    finishItem(conferenciaPublicID) {
      this.conferenciaPublicID = conferenciaPublicID
      this.showFinish = true
    },
    visualizarExtrato(caixaID) {
      this.$router.push({
        name: 'extrato',
        params: {
          caixaID,
        },
      })
    },
    async confirmFinish() {
      try {
        await this.concluirConferencia({
          conferenciaPublicID: this.conferenciaPublicID,
        })
        this.showFinish = false
        this.successMessage('Conferência finalizada com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
  computed: {
    permission() {
      return Sequences.Conferencia.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
}
</script>
