<template>
  <conferencia-list-geral :items="aprovados" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ConferenciaListGeral from './ConferenciaListGeral.vue'

export default {
  name: 'ConferenciaListAprovados',
  components: { ConferenciaListGeral },
  computed: {
    ...mapGetters('conferencia', ['aprovados']),
  },
  methods: {
    ...mapActions('conferencia', ['getConferencias']),
    async getItems(params) {
      try {
        await this.getConferencias({ params })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
