<template>
  <v-card>
    <v-card-title style="color: red;"> Forma Pagamento Cancelados</v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="data" hide-default-footer>
        <template v-slot:[`item.total`]="{ item: { total } }">
          {{ toReal(total) }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card>
      <v-card-subtitle class="d-flex justify-space-between primary--text">
        <span>Total</span>
        <span
          ><strong>{{ toReal(total) }}</strong></span
        >
      </v-card-subtitle>
    </v-card>
  </v-card>
</template>

<script>
import { toReal } from '@/mixins/convertion'

export default {
  name: 'ConferenciaResumoFormaPagamentoCancelado',
  mixins: [toReal],
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Tipo', value: 'forma_pagamento_name' },
        { text: 'Quantidade', value: 'quantidade' },
        { text: 'Total', value: 'total' },
      ],
    }
  },
  computed: {
    total() {
      return this.data.reduce((acc, o) => acc + o.total, 0)
    },
  },
}
</script>
