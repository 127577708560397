<template>
  <conferencia-base-dialog
    confirmarLabel="Aprovar"
    :value="value"
    @cancelar="$emit('cancelar')"
    @confirmar="$emit('aprovar')"
    @close="$emit('cancelar')"
  >
    <template #title> Deseja realmente aprovar este envelope? </template>
  </conferencia-base-dialog>
</template>

<script>
import ConferenciaBaseDialog from './ConferenciaBaseDialog.vue'

export default {
  name: 'ConferenciaAprovar',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  components: { ConferenciaBaseDialog },
}
</script>
