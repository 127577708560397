<template>
  <div>
    <conferencia-reprovar
      :value="showReprovar"
      @cancelar="showReprovar = false"
      @reprovar="confirmReprovar"
    />

    <conferencia-aprovar
      :value="showAprovar"
      @cancelar="showAprovar = false"
      @aprovar="confirmAprovar"
    />

    <conferencia-base-dialog
      max-width="1200px"
      :value="value"
      @close="finishResume"
    >
      <template #title> Resumo - Envelope Nº {{ item.numero }} </template>
      <template #subtitle> {{ item.colaborador_nome.toUpperCase() }} </template>
      <template #text>
        <v-row justify="center">
          <v-col>
            <v-row>
              <v-col>
                 <conferencia-resumo-forma-pagamento
                    class="mt-4"
                    :data="resumoFormaPagamento"
                  />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                 <conferencia-resumo-forma-pagamento-cancelado
                    class="mt-4"
                    :data="resumoFormaPagamentoCancelado"
                  />
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <conferencia-resumo-servico class="mt-4" :data="resumoServico" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <conferencia-resumo-servico-cancelado class="mt-4" :data="resumoServicoCancelado" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <conferencia-resumo-total
              class="mt-4"
              :totais="totais"
              :resumoServico="resumoServico"
              :resumoServicoCancelado="resumoServicoCancelado"
            />
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-row justify="center" class="py-4">
          <v-col cols="6" class="d-flex justify-space-around">
            <v-btn color="error" v-if="canReprovar" @click="reprovar(item.id)"
              ><v-icon>mdi-thumb-down</v-icon></v-btn
            >
            <v-btn color="secondary" v-if="canAprovar" @click="aprovar(item.id)"
              ><v-icon>mdi-thumb-up</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
            <v-btn
              v-if="canIntegracao"
              color="primary"
              dark
              class="mb-2"
              @click="enviarIntegracao(item.id)"
              >Enviar p/ integração</v-btn
            >
        </v-row>
      </template>
    </conferencia-base-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Sequences, CustomPermissions, } from '@/utils/permissions'
import ConferenciaBaseDialog from '@/components/financeiro/conferencia/ConferenciaBaseDialog.vue'
import ConferenciaReprovar from '@/components/financeiro/conferencia/ConferenciaReprovar.vue'
import ConferenciaAprovar from '@/components/financeiro/conferencia/ConferenciaAprovar.vue'
import ConferenciaResumoFormaPagamento from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoFormaPagamento.vue'
import ConferenciaResumoFormaPagamentoCancelado from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoFormaPagamentoCancelado.vue'
import ConferenciaResumoServico from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoServico.vue'
import ConferenciaResumoServicoCancelado from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoServicoCancelado.vue'
import ConferenciaResumoTotal from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoTotal.vue'
import caixaAPI from '@/services/caixa'

export default {
  name: 'ConferenciaResumo',
  inject: ['finishResume'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ConferenciaBaseDialog,
    ConferenciaReprovar,
    ConferenciaAprovar,
    ConferenciaResumoFormaPagamento,
    ConferenciaResumoServico,
    ConferenciaResumoTotal,
    ConferenciaResumoServicoCancelado,
    ConferenciaResumoFormaPagamentoCancelado,
  },
  data() {
    return {
      caixaID: null,
      showReprovar: false,
      showAprovar: false,
      resumoFormaPagamento: [],
      resumoFormaPagamentoCancelado: [],
      resumoServico: [],
      resumoServicoCancelado: [],
      totais: {},
    }
  },
  computed: {
    telaConferencia() {
      return Sequences.Conferencia.toString()
    },
    canAprovar() {
      return this.$canDo(CustomPermissions.APROVAR_CONFERENCIA, this.telaConferencia)
    },
    canReprovar() {
      return this.$canDo(CustomPermissions.REPROVAR_CONFERENCIA, this.telaConferencia)
    },
    ...mapState('auth', ['empresaAtual']),
    canIntegracao(){
      return this.empresaAtual.public_id == '9c027477-7337-4b6f-b686-9079a35dd1c0'
    },
  },
  methods: {
    ...mapActions('conferencia', ['aprovarCaixa', 'reprovarCaixa']),
    ...mapActions('caixa', ['getResumoCaixa']),
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('conferencia', ['getConferencias','getEmAberto']),

    reprovar(caixaID) {
      this.showReprovar = true
      this.caixaID = caixaID
    },
    async confirmReprovar(form) {
      this.showReprovar = false
      try {
        await this.reprovarCaixa({
          inputData: { caixa: this.caixaID, ...form },
        })
        this.successMessage('Caixa reprovado com sucesso!')
        this.finishResume()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    aprovar(caixaID) {
      this.showAprovar = true
      this.caixaID = caixaID
    },
    enviarIntegracao(caixaID) {
      this.caixaID = caixaID
      caixaAPI.envia_integracao(this.empresaAtual.public_id, caixaID)
    },
    async confirmAprovar() {
      this.showAprovar = false
      try {
        await this.aprovarCaixa({ inputData: { caixa: this.caixaID } })
        this.successMessage('Caixa aprovado com sucesso!')
        this.finishResume()
        await this.getConferencias({})
        await this.getEmAberto({})
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
  },
  async created() {
    try {
      const {
        resumo_forma_pagamento,
        resumo_forma_pagamento_cancelado,
        resumo_servico,
        resumo_servico_cancelado,
        total_caminhoes,
        total_entrada_manual,
        total_retirada,
      } = await this.getResumoCaixa(this.item.id)
      this.resumoFormaPagamento = resumo_forma_pagamento
      this.resumoFormaPagamentoCancelado = resumo_forma_pagamento_cancelado
      this.resumoServico = resumo_servico
      this.resumoServicoCancelado = resumo_servico_cancelado
      this.totais = {
        total_em_caixa: this.item.valor_em_caixa,
        total_caminhoes,
        total_entrada_manual,
        total_retirada,
      }
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
</script>
