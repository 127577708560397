<template>
  <conferencia-base-dialog
    confirmarLabel="Salvar"
    :value="value"
    @cancelar="finishEdit"
    @confirmar="save"
    @close="finishEdit"
  >
    <template #title> Envelope Nº {{ item.caixa.numero }} </template>
    <template #text>
      <validation-provider
        name="observacao"
        rules="required"
        v-if="item.status === ConferenciaStatus.Reprovado.name"
      >
        <v-text-field
          class="mb-4"
          v-model="form.observacao"
          label="Observação"
          name="observacao"
          hint="Motivo da reprovação"
          persistent-hint
        />
      </validation-provider>
      <validation-provider name="valor_conferido" rules="required">
        <vuetify-money
          v-model="form.valor_conferido"
          label="Valor Conferido"
          name="valor_conferido"
          type="number"
          hint="Valor real em caixa"
          persistent-hint
          :options="options"
        />
      </validation-provider>
    </template>
  </conferencia-base-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import { ConferenciaStatus } from '@/utils/conferencia'

import ConferenciaBaseDialog from '@/components/financeiro/conferencia/ConferenciaBaseDialog.vue'

export default {
  name: 'ConferenciaEdit',
  inject: ['finishEdit'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  components: { ConferenciaBaseDialog },
  data() {
    return {
      form: {
        observacao: this.item.observacao,
        valor_conferido: this.item.valor_conferido,
      },

      ConferenciaStatus,

      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 7,
        precision: 2,
      },
    }
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('conferencia', ['editConferencia']),

    async save() {
      try {
        await this.editConferencia({
          conferenciaPublicID: this.item.public_id,
          inputData: this.form,
        })
        this.successMessage('Conferência atualizada com sucesso!')
        this.finishEdit()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
