<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Conferência">
          <conferencia-list />
          <conferencia-edit v-if="isEditing" :value="isEditing" :item="item" />
          <conferencia-resumo
            v-else-if="isResume"
            :value="isResume"
            :item="item"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConferenciaList from './ConferenciaList.vue'
import ConferenciaEdit from './ConferenciaEdit.vue'
import ConferenciaResumo from './ConferenciaResumo.vue'

export default {
  name: 'Conferência',
  provide() {
    return {
      editItem: (item) => {
        this.isEditing = true
        this.item = item
      },
      resumeItem: (item) => {
        this.isResume = true
        this.item = item
      },
      finishEdit: () => {
        this.isEditing = false
        this.item = {}
      },
      finishResume: () => {
        this.isResume = false
        this.item = {}
      },
    }
  },
  components: { ConferenciaList, ConferenciaEdit, ConferenciaResumo },
  data() {
    return {
      page: {
        title: 'Conferência de Caixa',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: false,
        },
        {
          text: 'Conferência',
          disabled: true,
        },
      ],
      isEditing: false,
      isResume: false,
      item: {},
    }
  },
}
</script>
